import React from "react";
import HyperLogo from "../../assets/images/hyperlogo.png";
import "../../assets/hypdex.css";
import "../../assets/hypdex-responsive.css";
import moment from "moment";
import { Link } from "gatsby";

const BlogCard = ({ item }) => {
  const mainImage = item.mainImage.asset.url;
  const date = item.publishedAt;
  const published_date = moment(date).format("DD MMM YYYY");
  const readingTimeInMinutes = item.readingTimeInMinutes;

  return (
    <>
      <section id="card" className="blog-card pb-10">
        <div className="bg-card d-flex flex-column justify-content-between">
          <div>
            <img
              src={mainImage}
              alt=""
              className="mt-1"
              style={{ width: "225px", height: "200px", objectFit: "contain" }}
            />
            <Link to={`/blog/${item.slug.current}`}>
              <h4 className="mt-2 text-white mb-2 cursor-pointer">
                {item.title}
              </h4>
            </Link>
          </div>

          <div className="bottom d-flex justify-content-between align-items-center ps-2 pr-2 mb-2">
            <div className="logo-hyper">
              <img src={HyperLogo} alt="" />
            </div>
            <div className="author ms-2 text-left">
              <h5 className="text-white">{item.authors[0].author.name}</h5>

              <h6 className="date text-white text-start">{published_date}</h6>
            </div>

            <div>
              <h6 className="text-white">{readingTimeInMinutes} Min Read</h6>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogCard;
