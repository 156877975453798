//Lib
import React from "react";
import { withTranslation } from "react-i18next";
import { PortableText } from "@portabletext/react";
import { graphql } from "gatsby";
import moment from "moment";

// Components
import BlogCard from "../modules/blog/blogCard";
import { Figure } from "../modules/blog/Figure";

// Styles
import "../assets/hypdex.css";
import "../assets/hypdex-responsive.css";

//Content
import HeroImage from "../assets/images/heroImage.png";
import HyperLogo from "../assets/images/hyperlogo.png";

const BlogPost = ({ pageContext }) => {
  const { title, image, published, authorName, excerpt, body, readingTimeInMinutes, posts } =
    pageContext;
  const published_date = moment(published).format("DD MMM YYYY");

  return (
    <>
      <section className="page-header" style={{ marginBottom: "40px" }}>
        <div className="page-header-bg"></div>

        <div className="page-header-shape-1"></div>
        <div className="page-header-shape-2"></div>
        <div className="page-header-shape-3"></div>

        <div className="container text-center">
          <div className="page-header__inner">
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className=" ">
                  <img src={HeroImage} alt="" />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <h2>Blog</h2>
                <hr />
                <p>
                  The latest articles and updates from Hyperdex.
                </p>
              </div>
            </div>
          </div>

          <div className="mt-5 text-center">
            <div className="mt-5">
              <h2 className="text-center mt-5 fixedincome">{title}</h2>
            </div>
            <div className="mt-5">
              <img src={image} alt="blog 1" style={{ width: "100%" }} />
            </div>
          </div>

          <div className="bottom d-flex align-items-center ps-2 pr-2 mb-2  mt-3">
            <div className="logo-hyper">
              <img src={HyperLogo} alt="" />
            </div>
            <div className="author ms-2 text-left">
              <h5 className="text-white">{authorName}</h5>
              <div className="d-flex">
                <h6 className="date text-white text-start">{published_date}</h6>
                <h6 className="text-white ms-3">{readingTimeInMinutes} Min Read</h6>
              </div>
            </div>
          </div>

          <div className="mt-5 text-start">
            <p className="text-white text-start">{excerpt}</p>
          </div>

          <div className="mt-5 text-start text-white blogBody">
            {body && <PortableText value={body} />}
          </div>

          <div className="mt-5">
            <h2 className="text-center mt-5 fixedincome">Featured Articles</h2>
          </div>
          <div className="row mt-5 custom-row">
            {posts.map((item) => (
              <div className="col-md-3 mt-5">
                <BlogCard item={item} key={item.title} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogPost;
